@use '../includes' as *;

$featured-news-bp: $sm;

.featured_news_thumbnail_wrapper {
  display: flex;
  flex-direction: column;
  width: clamp(9rem, 22.857vw + 1.686rem, 34.6rem);

  @include breakpoint($featured-news-bp) {
    display: flex;
    flex-direction: row;
    gap: clamp(1.2rem, 0.595vw + 0.743rem, 1.6rem);
    max-width: clamp(34.6rem, 6.607vw + 32.486rem, 42rem);
  }
}

.featured_news_thumbnail_image {
  width: 100%;
  border-radius: $radius-ml;
  outline: var(--border-thickness) solid transparent;

  @include breakpoint($featured-news-bp) {
    width: clamp(9rem, 11.161vw + 5.429rem, 21.5rem);
  }

  @media (hover: hover) {
    &:hover {
      transition: outline-color var(--duration) ease-in;
      outline-color: $white;
    }
  }
}

.featured_news_thumbnail_description {
  display: flex;
  flex-direction: column;

  @include breakpoint($featured-news-bp) {
    width: clamp(10rem, 12.649vw + 0.286rem, 18.5rem);
  }
}

.featured_news_thumbnail_description_source {
  display: none;

  @include breakpoint($featured-news-bp) {
    display: inline-block;

    @include h6;

    font-size: $f10;
    text-transform: uppercase;
  }
}

.featured_news_thumbnail_description_story {
  @include body-copy-3;
  @include typography-truncate(2);

  margin-block-start: $g8;

  @include breakpoint($featured-news-bp) {
    @include h5;
  }

  // Custom breakpoint truncating at 3 lines
  // won't spill past the height of the image
  @include breakpoint(1200px) {
    @include typography-truncate(3);
  }
}

@use '../includes' as *;

// i know these are mostly pass throughs to existing variables, but i want it to
// be clear that these container breakpoints are for the player component
// and are imported into other pieces inside this component
$smbp: 610px;
$mdbp: $md;
$mdlgbp: $mdlg;
$lgbp: $lg;

@mixin video-player-body-copy() {
  @include body-copy-3;

  @include containerminwidth($mdlgbp) {
    @include body-copy-1;
  }
}

.video_player_iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 0;
  background-color: $midnight;
}

.video_player_container {
  container-type: inline-size;
  position: relative;
  // keeps player from getting _too_ small relative to the viewport
  min-width: 33vw;
  // this keeps the player from being so tall that the controls aren't visible
  max-height: calc(100vh - var(--navigation-height));
  aspect-ratio: 16 / 9;
  margin-inline: auto;

  // Div here represents the overlay
  &:has(iframe:hover) {
    > div {
      opacity: 1;
    }
  }
}

.video_image {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  object-fit: cover;
  mask-image: linear-gradient(
    to top,
    transparent 5%,
    rgb(0 0 0 / 30%) 40%,
    black 100%
  );

  @include containerminwidth($lgbp) {
    mask-image: linear-gradient(
      to top,
      transparent 5%,
      rgb(0 0 0 / 30%) 20%,
      black 55%
    );
  }
}

@use '../includes' as *;

.sponsorship_video_help_row {
  max-width: $max-width;
  padding-block: $g20;
  padding-inline: var(--spacing-inline);

  &:has(div + div) {
    display: flex;
    flex-direction: column;
    gap: $g16;

    @include breakpoint($sm) {
      flex-direction: row;
      gap: $g48;
    }
  }

  // stylelint-disable-next-line
  &:global(.sponsorship_video_help_row__bottom) {
    margin-block-end: 0;
  }
}

.sponsorship_video_help_row_video_help {
  @include breakpoint($max: $md) {
    display: none;
  }
}

@use '../../includes' as *;

.my_list_button {
  @include btn;
  @include ghost-btn($white);
}

button.my_list_button__icon_only {
  @include btn;
  @include ghost-btn($white);

  & {
    display: flex;
    justify-content: center;
    width: 36px;
    aspect-ratio: 1;
    padding: 0;
  }

  @include breakpoint($sm) {
    // yes this is redundant but it's overriding the default btn
    padding: 0;
  }

  svg {
    width: 55%;
    translate: 0 -1%;
  }
}

button.my_list_button__kabob_menu {
  display: flex;
  justify-content: center;
  all: unset;
  cursor: pointer;

  svg {
    width: 13px;
    height: 12px;
    margin-inline-end: $g8;
    fill: $white;
  }

  &:hover,
  &:focus,
  &:active {
    transition: color var(--duration-half) ease-in;
    color: var(--link-active-color);

    svg,
    rect {
      transition: fill var(--duration-half) ease-in;
      fill: var(--link-active-color);
    }
  }
}

@use '../includes' as *;

.livetv_row {
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  [class*='splide__track'] {
    // allows for expanded poster to be visible on hover
    overflow: visible;
  }
}

.livetv_row_title {
  @include live-tv-dot;
  @include h2;

  display: flex;
  align-items: center;
  margin-block-end: clamp(#{$g8}, 2vw, #{$g24});
}

li.channel {
  width: clamp(140px, 40vw, 316px);

  @include breakpoint($sm) {
    width: clamp(140px, 25vw, 316px);
  }

  &:hover,
  &:focus {
    z-index: 14;
  }
}

// Need to bump the specificity to apply
// the style else the original splide styles
// will override it in Amplify & Edcar environments
button.livetv_row__splide_arrow {
  @include splide-arrow;
}

.livetv_row__loading {
  width: 100%;
  aspect-ratio: 130 / 19;
  padding-block-start: 10%;
}

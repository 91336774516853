@use '../includes' as *;

$spacing: clamp($g8, 1.2vw + 0.5em, $g16);

.how_to_watch {
  display: flex;
  flex-direction: column;
  gap: $spacing;
}

.how_to_watch__title {
  @include h3;
}

.how_to_watch__text {
  @include body-copy-3($pebble);

  @include breakpoint($lg) {
    @include body-copy-1;

    color: var(--text-color);
  }
}

.how_to_watch__links {
  li {
    &:not(:last-child) {
      margin-block-end: $spacing;
    }
  }

  a {
    @include h5;

    &:hover,
    &:focus {
      color: $pebble;
    }

    svg {
      margin-inline-start: $g8;
      translate: 0 2px;
    }
  }
}

@use '../includes' as *;
@use './VideoPlayer.module.scss' as vp;

.video_player_overlay {
  display: none;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-block: $g56 $g128;
  padding-inline-start: var(--spacing-inline);
  background: linear-gradient(
    to bottom right,
    $midnight 10%,
    rgb($midnight, 0) 55%
  );
  background-repeat: no-repeat;
  color: $white;
  font-size: $f18;
  pointer-events: none;

  @include containerminwidth(vp.$mdbp) {
    display: flex;
  }

  @include containerminwidth(vp.$lgbp) {
    padding-inline-start: $g52;
  }
}

.video_player_overlay_hidden {
  visibility: hidden;
  opacity: 0;
}

.video_player_overlay_hidden_transition {
  // values match VideoJS defaults for hiding the controls
  // https://videojs.com/blog/hiding-and-showing-video-player-controls/
  transition:
    visibility 1.5s,
    opacity 1.5s;
}

.overlay_details {
  display: flex;
  grid-gap: $g12;
  flex-direction: column;
  gap: $g12;
  width: 40%;
  padding-block-start: $g24;
  pointer-events: auto;
}

.video_player_overlay:has(~ [class*='passport_benefit_screen']) {
  .overlay_details {
    position: absolute;
    inset: auto auto $g20 var(--spacing-inline);
    padding-block-start: 0;
  }
}

.nav_link {
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.nav_link__title {
  @include h3;
}

.nav_link__logo {
  width: clamp(100px, 20vw + 1em, 200px);
}

.video_title {
  @include h3;
}

.video_meta_data,
.video_description,
.airing_expiring_rating {
  @include vp.video-player-body-copy;
}

.video_meta_data {
  display: flex;
  align-items: center;
  color: $fog;
}

.compass_rose_icon {
  width: 16px;
  height: 16px;
  padding-inline-end: $g4;
}

.video_audio_description {
  display: flex;
  align-items: center;
  gap: $g8;
  font-size: $f14;
  font-weight: $bold;
}

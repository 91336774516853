@use '../includes' as *;

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading_indicator {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  @media (prefers-reduced-motion: reduce) {
    animation: pulse 1s linear infinite;
  }
}

.loading_indicator__spinner {
  width: 70px;
  text-align: center;

  div {
    display: inline-block;
    width: 18px;
    height: 18px;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    border-radius: 100%;
    background-color: $coral;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
  }
}

@use '../includes' as *;

$latest-news-bp: $sm;
$overlay-inline-inset: 4%;

.latest_news_thumbnail_wrapper {
  display: inline-block;
  position: relative;
  width: clamp(14rem, 44.643vw - 0.286rem, 64rem);
  cursor: pointer;

  &::after {
    @include breakpoint($latest-news-bp) {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius-ml;
      opacity: 0.6;
      background: linear-gradient(to top, $midnight 20%, transparent 70%);
      pointer-events: none;
    }
  }

  .latest_news_thumbnail_image {
    transition: outline-color var(--duration) ease-in;
    border-radius: $radius-ml;
    outline: var(--border-thickness) solid transparent;
  }

  @media (hover: hover) {
    &:hover {
      .latest_news_thumbnail_image {
        outline-color: $white;
      }
    }
  }
}

.latest_news_thumbnail {
  display: block;
  width: 100%;
}

.latest_news_thumbnail_overlay {
  width: 100%;
  margin-block-start: $g8;

  @include breakpoint($latest-news-bp) {
    position: absolute;
    inset-inline: $overlay-inline-inset;
    inset-block-end: 10%;
    z-index: 2;
  }
}

.latest_news_thumbnail_overlay_source {
  @include over-title;

  margin-block-end: $g4;
}

.latest_news_thumbnail_overlay_story {
  @include typography-truncate(2);
  @include body-copy-3($fog);

  @include breakpoint($latest-news-bp) {
    @include h3;

    max-width: calc(100% - ($overlay-inline-inset * 2));
  }
}

@use '../includes' as *;

.caret_prev_icon {
  height: 1em;
  transition: fill var(--duration-third) ease-in;
  fill: $white;
}

.video_audio_description {
  display: flex;
  align-items: center;
  gap: $g8;
  width: max-content;
  font-size: $f14;
  font-weight: $bold;

  &:hover,
  &:focus-visible,
  &:active {
    .caret_prev_icon {
      fill: var(--link-active-color);
    }
  }
}

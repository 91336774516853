@use '../includes' as *;

.related_content {
  display: flex;
  flex-direction: column;
  gap: $g40;

  @include breakpoint($sm) {
    gap: $g80;
  }
}

.related_content_related_links_title {
  @include h3;

  margin-block-end: clamp($g16, 3vw, $g20);
}

.related_content_related_links {
  display: flex;
  flex-direction: column;
  gap: $g16;
  margin-block-end: $g16;
}

.related_content_related_link a {
  @include h5;

  display: inline-flex;
  align-items: center;
  gap: $g8;
}

.related_content_related_promos_title {
  @include h2;

  margin-block-end: clamp($g12, 3vw, $g20);
}

.related_content_related_promos {
  display: flex;
  gap: var(--gutter);
  width: 100%;
  margin-block-end: $g20;

  @include breakpoint($max: $xs) {
    max-width: calc(100vw - var(--spacing-inline));
    overflow-y: visible;

    @include horizontal-scroll-with-hidden-scrollbar;
  }
}

.related_content_related_promo {
  display: flex;
  container-type: inline-size;
  flex-direction: column;
  gap: clamp($g4, 1.4vw, $g8);
  width: clamp(140px, 25%, 316px);
  // the layout won't work without this min width
  // the lower value in the clamp() function
  // isn't enough
  min-width: 140px;
}

.related_content_related_promo_image_link {
  position: relative;

  @include image-thumbnail-link(calc(3 / 2));

  > svg {
    position: absolute;
    inset: 0 0 auto auto;
    // we want the icon to appear 18px wide
    // 16px + 18px + 4px = 38px
    width: 38px;
    padding: $g4 $g4 $g16 $g16;
    background-image: radial-gradient(
      ellipse at top right,
      rgba($blue-gray, 50%) 30%,
      rgba($blue-gray, 0%) 70%
    );
    fill: $fog;

    @include breakpoint($sm) {
      // 40px + 18px + 12px = 70px
      width: 70px;
      padding: $g12 $g12 $g40 $g40;
    }
  }
}

.related_content_related_promo_title {
  @include h4;
  @include typography-truncate(2);
}

.related_content_related_promo_description {
  @include body-copy-3($pebble);

  @include breakpoint($max: $sm) {
    @include typography-truncate(2);
  }
}

@use '../includes' as *;

.sponsorship_text {
  color: $pebble;
  font-size: $f12;
  font-style: italic;
  line-height: 1.5;

  p:has(+ p) {
    margin-block-end: $g8;
  }

  button {
    padding: 0 0 0 $g4;
    border: 0;
    background-color: transparent;
    color: currentcolor;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }

  a {
    color: currentcolor;
    text-decoration: underline;
  }
}

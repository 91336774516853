@use '../../../../components/includes' as *;

.results {
  display: flex;
  container-type: inline-size;
  flex-direction: column;
  width: 100%;
}

.results_text_header {
  @include h4;

  align-self: center;
  margin-block: $g12;
}

.results_text_small {
  @include body-copy-2;

  align-self: center;
  margin-block-start: $g8;
}

.loading_indicator {
  width: 100px;
  height: 100px;
  margin: $g48 auto $g32 auto;

  div {
    div {
      background-color: $pebble;
    }
  }
}

.load_more_button {
  @include btn;
  @include ghost-btn($white);

  & {
    align-self: center;
    margin-block: $g24;
  }
}

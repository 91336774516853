@use '../../../../../../components/includes' as *;

.show_collection_page__container {
  margin-block: var(--spacing-block);
  margin-inline: var(--spacing-inline);

  @include content-layout-with-ad;

  @include breakpoint($max: $sm) {
    margin-block-start: $g24;
  }
}

.show_collection_page {
  @include video-detail-thumbnail-list;
}

.show_collection_page__details {
  a {
    display: block;

    @include h2($white);

    &:hover,
    &:focus-visible,
    &:active {
      color: var(--link-active-color);
    }

    margin-block-end: $g20;

    @include breakpoint($sm) {
      margin-block-end: $g24;
    }
  }

  h1 {
    @include h4($moon);

    margin-block-end: $g8;
  }

  p {
    max-width: 590px;

    @include body-copy-3($pebble);

    @include breakpoint($sm) {
      @include body-copy-1($pebble);
    }
  }
}

.show_collection_page__videos_list {
  @include video-detail-thumbnail-list;
}

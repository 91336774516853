@use '../includes' as *;

.unavailable_message {
  @include body-copy-1;

  display: inline-flex;
  position: absolute;
  inset: 50% auto auto 50%;
  z-index: 4;
  padding: $g24;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: rgb($midnight, 0.6);
  backdrop-filter: blur(15px);
  text-align: center;
}

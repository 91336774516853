@use '../includes' as *;

.buy_now {
  display: flex;
  align-items: center;
}

.buy_now__title {
  @include h5;

  text-wrap: nowrap;
}

.buy_now__links {
  display: flex;
  align-items: flex-end;
  gap: clamp($g16, 5vw, $g32);
  margin-inline-start: clamp($g16, 5vw, $g32);

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $g4;
    font-size: $f12;
  }

  svg,
  path {
    fill: currentcolor;
  }

  svg {
    width: 20px;
  }
}

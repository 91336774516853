@use '../includes' as *;

// Approximating the splide arrows to the center of the
// thumbnail image vs. the splide slide
.latest_news_row {
  @include breakpoint($max: $sm) {
    [class*='splide__arrow--prev'],
    [class*='splide__arrow--next'] {
      inset-block-start: 35%;
    }
  }
}

.latest_news_row_title {
  @include h2;

  margin-block-end: clamp(0.8rem, 1.071vw + 0.457rem, 2rem);
}

// Need to bump the specificity to apply
// the style else the original splide styles
// will override it in Amplify & Edcar environments
button.latest_news_row_splide_arrow {
  @include splide-arrow;
}

@use '../includes' as *;

.news_row {
  display: flex;
  flex-direction: column;
  gap: clamp(1.2rem, 1.786vw + 0.629rem, 3.2rem);
  margin-block: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  [class*='splide__track'] {
    // allows for expanded poster to be visible on hover
    overflow: visible;
  }

  [class*='splide__list'] {
    gap: var(--gutter);
  }

  @include carousel-hover-dim('latest_news_thumbnail_wrapper');
  @include carousel-hover-dim('featured_news_thumbnail_wrapper');
}

@use '../includes' as *;

$bp: 220px;

.channel_thumbnail {
  --channel-thumbnail-padding: clamp(#{$g8}, 5vw, #{$g16});

  display: inline-block;
  container-type: inline-size;
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  transition-property: scale, opacity, border-radius, outline-color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in-out;
  border-radius: 7.5px;
  outline: 3px solid transparent;
  background-color: $midnight;

  &:hover,
  &:focus {
    z-index: 14;
    border-radius: 3px;
    outline-color: $white;
    color: var(--text-color);
    scale: 1.05;
  }

  &.no_image_fallback {
    background-color: $pbs-blue;
  }
}

.channel_name {
  @include h3;

  display: flex;
  align-items: center;
}

.live_indicator {
  display: inline-flex;
  align-items: center;
  margin-inline-end: $g4;

  @container (min-width: #{$bp}) {
    margin-inline-end: $g8;
  }
}

.signal_icon {
  width: 15px;
  height: 12px;

  @container (min-width: #{$bp}) {
    display: none;
  }
}

span.live_badge {
  @container (max-width:  #{$bp + 1px}) {
    display: none;
  }
}

.channel_logo {
  display: inline-block;
  width: auto;
  max-width: 120px;
  max-height: 16px;

  @container (min-width: #{$bp}) {
    max-height: 24px;
  }
}

.channel_thumbnail_image {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  aspect-ratio: 16 / 9;
  opacity: 0.6;
}

.channel_thumbnail_info {
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: var(--channel-thumbnail-padding);
}

.show_title {
  @include h4;
  @include typography-truncate(1);
}

.episode_title {
  @include body-copy-1;
  @include typography-truncate(1);
}
